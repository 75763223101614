import React, { useState } from "react"
import Container from "../common/Container"
import imageHero1 from "../../assets/images/hero-1.png"
import imageHero2 from "../../assets/images/hero-2.png"
import hero1 from "../../assets/lottie/hero-1.json"
import hero2 from "../../assets/lottie/hero-2.json"
import CustomSwiper from "../common/CustomSwiper"
import { SwiperSlide } from "swiper/react"
import Divider from "../common/Divider"
import { DEVICES } from "../../config"
import styled from "styled-components"
import Lottie from "react-lottie-player"
import GetStarted from "./GetStarted"

const TITLE = "Science's most trusted program for better performance"

const DESCRIPTION =
  "PNOĒ analyzes your breath and crafts the most personalized program for performance optimization."

const Title = styled.h2`
  font-size: 32px;
  width: 532px;
  font-weight: bold;
  line-height: 1.25;
  margin: 0;
  margin-bottom: 16px;
  z-index: 16;
  position: relative;

  @media (max-width: ${DEVICES.tablet}px) {
    font-size: 20px;
    text-align: center;
    width: unset;
    margin-top: 32px;
    text-align: center;
    padding: 0 16px;
  }
`

const Description = styled.h3`
  font-size: 16px;
  line-height: 1.5;
  font-weight: normal;
`

function HeroSlide({ image, lottie, play }) {
  return (
    <div
      css={`
        display: flex;
        align-items: center;
        background: var(--main);

        @media (max-width: ${DEVICES.tablet}px) {
          height: 388px;
          position: relative;
        }
      `}
    >
      {image}
      {play ? (
        <Lottie
          play={play}
          animationData={lottie}
          css={`
            height: 280px;
            width: 280px;
            margin-left: auto;

            @media (max-width: ${DEVICES.tablet}px) {
              height: 200px;
              width: 200px;
              margin-left: unset;
              position: absolute;
              right: 0;
              top: 0;
            }
          `}
        />
      ) : (
        <div
          css={`
            @media (max-width: ${DEVICES.tablet}px) {
              height: 230px;
              width: 230px;
              margin-left: unset;
            }
          `}
        ></div>
      )}
    </div>
  )
}

function Hero() {
  const [index, setIndex] = useState(0)

  return (
    <Container
      light
      css={`
        position: relative;

        hr {
          position: relative;
          z-index: 10;
        }
      `}
    >
      <Container
        compact
        css={`
          display: grid;
          grid-template-columns: 358px 666px;
          gap: 52px;
          align-items: center;

          @media (max-width: ${DEVICES.tablet}px) {
            width: 100%;
            grid-template-columns: 100vw;
            gap: 0;
          }
        `}
      >
        <div>
          <Title>{TITLE}</Title>
          <Description
            css={`
              @media (max-width: ${DEVICES.tablet}px) {
                display: none;
              }
            `}
          >
            {DESCRIPTION}
          </Description>
          <div
            css={`
              @media (max-width: ${DEVICES.tablet}px) {
                display: none;
              }
            `}
          >
            <div
              css={`
                margin-top: 32px;
              `}
            >
              <GetStarted />
            </div>
          </div>
        </div>
        <div>
          <CustomSwiper
            allowTouchMove={false}
            autoplay={{
              delay: 4500,
              disableOnInteraction: false,
            }}
            effect="fade"
            id="hero-slider"
            loop
            slidesPerView={1}
            css={`
              .hero-slider-next,
              .hero-slider-prev {
                display: none;
              }
            `}
            onSlideChange={swiper => setIndex(swiper.realIndex)}
          >
            <SwiperSlide>
              <HeroSlide
                title={TITLE}
                description={DESCRIPTION}
                image={
                  <img
                    css={`
                      height: 496px;
                      margin-top: 42px;

                      @media (max-width: ${DEVICES.tablet}px) {
                        height: 100%;
                      }

                      @media (max-width: ${DEVICES.mobile}px) {
                        margin-left: -100px;
                        height: 100%;
                      }
                    `}
                    src={imageHero1}
                    alt="Hero 1"
                  />
                }
                lottie={hero1}
                play={index === 0}
              />
            </SwiperSlide>
            <SwiperSlide>
              <HeroSlide
                title={TITLE}
                description={DESCRIPTION}
                image={
                  <img
                    css={`
                      height: 507.5px;
                      margin-top: 30.5px;

                      @media (max-width: ${DEVICES.tablet}px) {
                        margin-left: -10px;
                        height: 100%;
                      }
                    `}
                    src={imageHero2}
                    alt="Hero 2"
                  />
                }
                lottie={hero2}
                play={index === 1}
              />
            </SwiperSlide>
          </CustomSwiper>
        </div>
      </Container>
      <div
        css={`
          display: none;

          @media (max-width: ${DEVICES.tablet}px) {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        `}
      >
        <Description
          css={`
            text-align: center;
            margin: 0;
            padding: 32px 16px;
          `}
        >
          {DESCRIPTION}
        </Description>
        <div
          css={`
            margin-top: 0;
            margin-bottom: 32px;
          `}
        >
          <GetStarted />
        </div>
      </div>
      <Divider
        css={`
          margin: 0;
        `}
      />
    </Container>
  )
}

export default Hero
